import { useQuery } from "@tanstack/react-query"
import { Collapse, Tooltip } from "antd"
import { getDateString } from "helpers/getDateString"
import { useRegion } from "hooks/useRegion"
import React, { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import { GetReferenceNomenclatureResponse } from "types/api"
import ContentForm from "../../components/ContentForm"
import { SaveMenu } from "../../components/UI/SaveMenu"
import api from "../../helpers/api"
import { isEmptyObject } from "../../helpers/isEmptyObject"
import { hasPermissionForElementPage } from "../../helpers/permissions"
import {
    getCurrentValues,
    getFieldsFromServer,
    INIT_STATE_GROUP,
} from "../../store/fields/actions"
import { RootState } from "../../store/types"
import "../../styles/components/nomenclature-calculation-log.sass"
import NomenclatureDetailRequesiteRegions from "./NomenclatureDetail.RequesiteRegions"

const FillingRow = ({
    attribute,
    value,
}: {
    attribute: string
    value: string
}) => {
    return (
        <div className="row mt-2">
            <label className="col-xl-4">{attribute}</label>
            <div className="col-xl-8 d-flex align-items-end">{value}</div>
        </div>
    )
}

export const NomenclatureDetail: React.FC = () => {
    const user = useSelector((state: RootState) => state.user.user)
    const credentials = user?.credentials ?? []

    const { activeRegion } = useRegion()

    const fields: object[] = useSelector(
        (state: { [key: string]: any }) => state.fields.initFields
    )
    const currentValues = useSelector(
        (state: { [key: string]: any }) => state.fields.currentValues?.["main"]
    )

    const attributeName: string = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues["main"]?.name
    )

    const location: { [key: string]: any } = useLocation()
    const pageID: string = location.state.pageID || ""

    const dispatch = useDispatch()
    const routerParams: { [key: string]: string } = useParams()

    const title: string = routerParams.id ? "Редактирование" : "Создание"

    const onModifySendData = React.useCallback((data: any) => {
        for (const code in data) {
            // * Clear ID
            if (code === "id") {
                delete data[code]
            }
            if (typeof data[code] === "object" && data[code]?.id) {
                data[code] = data[code].id
            }
        }
    }, [])

    useEffect(() => {
        document.title = title
        dispatch(INIT_STATE_GROUP({ main: {} }))

        dispatch(getFieldsFromServer(`reference/${pageID}/fields`))
        dispatch(
            getCurrentValues(`reference/${pageID}`, routerParams.id, "main")
        )
    }, [])

    const { data: nomenclature } = useQuery<GetReferenceNomenclatureResponse>({
        queryKey: ["nomenclature", routerParams.id],
        queryFn: () =>
            api.getTyped("nomenclature/get", {
                id: routerParams.id,
            }),
    })

    const [pictureUrl, setPictureUrl] = useState(
        "https://fakeimg.pl/200x300/?retina=1&text=404&font=noto"
    )
    useQuery({
        queryKey: ["nomenclature-picture", routerParams.id],
        enabled: !!nomenclature,
        queryFn: () => api.getTyped(nomenclature!.pictureUrl, {}, true),
        onSuccess: (v) => setPictureUrl(nomenclature!.pictureUrl),
        onError: (v) =>
            setPictureUrl(
                "https://fakeimg.pl/200x300/?retina=1&text=404&font=noto"
            ),
    })

    const handleRecalculatePrices = async () => {
        await toast.promise(
            api.get(`v1/nomenclature/calculate-all-prices/${routerParams.id}`, {
                region: activeRegion?.value,
            }),
            {
                loading: "Обрабатываем...",
                success: <b>Пересчет успешно запущен!</b>,
                error: ({ response: { data: serverTextResponse } }) => {
                    const error =
                        serverTextResponse ?? "Произошла неизвестная ошибка"
                    return <b>{String(error)}</b>
                },
            },
            { duration: 3500 }
        )
    }

    const nomenclaturePrices = useMemo(() => {
        if (!nomenclature || !activeRegion) return []
        return (
            nomenclature.prices.find((el) => el.region === activeRegion.value)
                ?.prices_region ?? []
        )
    }, [nomenclature, activeRegion])

    const getPriceString = (
        price: GetReferenceNomenclatureResponse["prices"][number]["prices_region"][number]
    ) => {
        const basePrice = price.price
        const currency = price.currency?.isoCode ?? "[валюта не указана]"
        const chunks = [basePrice, currency]
        if (!price.priceIncludesVAT) {
            chunks.push(`/ ${price.priceWithVat} с НДС`)
        }
        return chunks.join(" ")
    }

    const handleUpdateStock = async (newValue: boolean) => {
        await toast.promise(
            api.put(`v1/nomenclature/update/${routerParams.id}`, null, {
                isInStock: newValue,
            }),
            {
                loading: "Обрабатываем...",
                success: <b>Сохранено!</b>,
                error: ({ response: { data: serverTextResponse } }) => {
                    const error =
                        serverTextResponse ?? "Произошла неизвестная ошибка"
                    return <b>{String(error)}</b>
                },
            },
            { duration: 3500 }
        )
    }

    return (
        <>
            <h1 className="h3 mb-3">
                {title}
                {routerParams.id
                    ? ` "${
                          (pageID === "uploaded-attribute" &&
                              routerParams.id) ||
                          attributeName ||
                          "..."
                      }"`
                    : null}
            </h1>
            <SaveMenu url={`reference/${pageID}`} onModify={onModifySendData} />
            <div className="control">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                            className="nav-link active"
                            id="edit-settings-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#edit-settings"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                        >
                            Настройка
                        </button>
                    </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="edit-settings"
                        role="tabpanel"
                        aria-labelledby="edit-settings-tab"
                    >
                        <div className="control-view">
                            <div className="control-view__main">
                                <div className="control-in">
                                    <h5>Основное</h5>
                                    {isEmptyObject(currentValues) ? (
                                        <div>Загрузка...</div>
                                    ) : (
                                        <div>
                                            <ContentForm
                                                isCreate={!routerParams.id}
                                                group={"main"}
                                                fields={fields}
                                            />
                                        </div>
                                    )}
                                </div>
                                {nomenclature && (
                                    <div className="row">
                                        <div className="col">
                                            <div className="control-in">
                                                <h5>Наполнение</h5>

                                                <FillingRow
                                                    attribute="Модель"
                                                    value={
                                                        nomenclature.model.name
                                                    }
                                                />
                                                {nomenclature?.classificationAccordance && (
                                                    <FillingRow
                                                        attribute="ТНВЭД"
                                                        value={
                                                            nomenclature
                                                                .classificationAccordance
                                                                .tnvedName
                                                        }
                                                    />
                                                )}
                                                {nomenclature?.classificationAccordance && (
                                                    <FillingRow
                                                        attribute="ОКРБ"
                                                        value={
                                                            nomenclature
                                                                .classificationAccordance
                                                                .okrbName
                                                        }
                                                    />
                                                )}
                                                <NomenclatureDetailRequesiteRegions
                                                    defaultValues={
                                                        nomenclature.nomenclatureRegionDiscontinued
                                                    }
                                                    targetKey="nomenclatureRegionDiscontinued"
                                                    nomenclatureId={
                                                        routerParams.id
                                                    }
                                                />

                                                <NomenclatureDetailRequesiteRegions
                                                    defaultValues={
                                                        nomenclature.nomenclatureRegionStock
                                                    }
                                                    targetKey="nomenclatureRegionStock"
                                                    nomenclatureId={
                                                        routerParams.id
                                                    }
                                                />

                                                {Object.entries(
                                                    nomenclature.renderedValues
                                                ).map(
                                                    (
                                                        [attribute, value],
                                                        idx
                                                    ) => (
                                                        <FillingRow
                                                            attribute={
                                                                attribute
                                                            }
                                                            value={value}
                                                            key={`row-${idx}`}
                                                        />
                                                    )
                                                )}
                                            </div>

                                            <div className="control-in">
                                                {hasPermissionForElementPage(
                                                    credentials,
                                                    "viewStartRecalculation"
                                                ) && (
                                                    <h5
                                                        style={{
                                                            marginBottom: 15,
                                                        }}
                                                        className="d-flex justify-content-between"
                                                    >
                                                        <span>
                                                            Отладка стоимостей
                                                        </span>
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                handleRecalculatePrices()
                                                            }
                                                            className="btn btn-outline-primary btn-sm"
                                                        >
                                                            Запустить пересчет
                                                        </button>
                                                    </h5>
                                                )}
                                                {!!nomenclaturePrices.length
                                                    ? null
                                                    : "У номенклатуры нет стоимостей в выбранном регионе"}

                                                {!!nomenclaturePrices.length && (
                                                    <Collapse
                                                        style={{
                                                            borderRadius: 4,
                                                        }}
                                                        items={
                                                            nomenclaturePrices.map(
                                                                (
                                                                    priceRecord,
                                                                    i
                                                                ) => ({
                                                                    key: i,
                                                                    label: (
                                                                        <div className="calculation-log__header">
                                                                            <span>
                                                                                {priceRecord
                                                                                    .typeOfPrice
                                                                                    ?.name ??
                                                                                    "Цена от поставщика"}
                                                                            </span>
                                                                            <span>
                                                                                {getDateString(
                                                                                    priceRecord.startAt
                                                                                )}
                                                                            </span>
                                                                            <span>
                                                                                {getPriceString(
                                                                                    priceRecord
                                                                                )}{" "}
                                                                            </span>
                                                                        </div>
                                                                    ),
                                                                    children: (
                                                                        <div>
                                                                            {priceRecord?.calculationLog?.map(
                                                                                (
                                                                                    log
                                                                                ) => (
                                                                                    <div className="calculation-log__row">
                                                                                        <span className="line">
                                                                                            <Tooltip
                                                                                                placement="right"
                                                                                                overlayInnerStyle={{
                                                                                                    textAlign:
                                                                                                        "center",
                                                                                                    maxWidth: 200,
                                                                                                }}
                                                                                                title={
                                                                                                    <>
                                                                                                        Строка
                                                                                                        кода,
                                                                                                        на
                                                                                                        которой
                                                                                                        произведена
                                                                                                        операция.
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    log.line
                                                                                                }
                                                                                            </Tooltip>
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                log
                                                                                                    .type
                                                                                                    .name
                                                                                            }
                                                                                        </span>
                                                                                        <span className="number">
                                                                                            <Tooltip
                                                                                                placement="right"
                                                                                                overlayInnerStyle={{
                                                                                                    textAlign:
                                                                                                        "center",
                                                                                                    maxWidth: 200,
                                                                                                }}
                                                                                                title={
                                                                                                    <>
                                                                                                        Число,
                                                                                                        используемое
                                                                                                        для
                                                                                                        применения
                                                                                                        операции.
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    log.number
                                                                                                }
                                                                                            </Tooltip>
                                                                                        </span>
                                                                                        <div className="comment">
                                                                                            {log.comment && (
                                                                                                <Tooltip
                                                                                                    placement="right"
                                                                                                    overlayInnerStyle={{
                                                                                                        textAlign:
                                                                                                            "center",
                                                                                                    }}
                                                                                                    title={
                                                                                                        <>
                                                                                                            Комментарий.
                                                                                                        </>
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            marginBottom: 10,
                                                                                                            display:
                                                                                                                "inline-block",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            log.comment
                                                                                                        }
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                            )}

                                                                                            <div className="calculation-log__entities">
                                                                                                {Object.entries(
                                                                                                    log.entities
                                                                                                ).map(
                                                                                                    ([
                                                                                                        id,
                                                                                                        name,
                                                                                                    ]) => {
                                                                                                        return (
                                                                                                            <div className="calculation-log__entity">
                                                                                                                <Tooltip
                                                                                                                    placement="right"
                                                                                                                    overlayInnerStyle={{
                                                                                                                        textAlign:
                                                                                                                            "center",
                                                                                                                    }}
                                                                                                                    title={
                                                                                                                        id
                                                                                                                    }
                                                                                                                >
                                                                                                                    <span>
                                                                                                                        {
                                                                                                                            name as string
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </Tooltip>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    ),
                                                                })
                                                            ) ?? []
                                                        }
                                                        defaultActiveKey={[1]}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="control-in col">
                                                <h5>Изображение</h5>

                                                <img
                                                    src={pictureUrl}
                                                    alt="Изображение двери"
                                                    style={{
                                                        maxWidth: "300px",
                                                        height: "auto",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SaveMenu url={`reference/${pageID}`} onModify={onModifySendData} />
        </>
    )
}
